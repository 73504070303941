export const globalFunction = {
  // global function
  formatNumber: function (number, options = null) {
    // if number is not a number => return number
    // else => return number format: 9,999,999.99 (float) or 9,999,999 (integer)
    if ([null, undefined, ""].includes(number)) return number;
    if (options == null) {
      options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      };
    }
    const numStr = new Intl.NumberFormat("ja-JP", options).format(number);
    return numStr !== "NaN" ? numStr : number;
  },
  formatDate: function (date_string, params = null, utcToJapan = true) {
    let result = this.formatDateToParts(date_string, params, utcToJapan);
    if (result.length == 0) return "";
    let dateTimeArray = result.map((item) => item.value);
    if (params && params.dateStyle == "long") {
      if (parseInt(dateTimeArray[2]) < 10) {
        dateTimeArray[2] = "0" + dateTimeArray[2];
      }
      if (parseInt(dateTimeArray[4]) < 10) {
        dateTimeArray[4] = "0" + dateTimeArray[4];
      }
    }
    if (params && params.timeStyle == "short") {
      if (parseInt(dateTimeArray[6]) < 10) {
        dateTimeArray[6] = "0" + dateTimeArray[6];
      }
    }
    return dateTimeArray.join("");
  },
  formatDateOld: function (date_string, params = null, utcToJapan = true) {
    // params = null => 2021/01/01
    // params = { dateStyle: "long"} => 2021年1月1日
    // utcToJapan = true => 2021/01/01 18:00:00 => 2021/01/02 03:00:00 (Japan time)
    if ([null, undefined, ""].includes(date_string)) return "";
    let date = new Date(date_string);
    if (utcToJapan) {
      // convert utc time to japan
      date = date.setHours(date.getHours() + 9);
    }
    if (params == null) {
      return new Intl.DateTimeFormat("ja-JP", { dateStyle: "medium" }).format(
        date
      );
    }
    return new Intl.DateTimeFormat("ja-JP", params).format(date);
  },
  formatDateToParts: function (date_string, params = null, utcToJapan = true) {
    // params = {dateStyle:"long"}  => result = [year, 年, month, 月, day, ...]
    // params = null => result = [year, /, month, /, day, ...]
    // Example Usage: Show Yeah年Month月
    // formatDateToParts(date_string, { dateStyle: "long" }).map((item) => item.value).slice(0, 4).join("")
    if ([null, undefined, ""].includes(date_string)) return [];
    let date = new Date(date_string);
    if (utcToJapan) {
      // convert utc time to japan
      date = date.setHours(date.getHours() + 9);
    }
    if (params == null) {
      return new Intl.DateTimeFormat("ja-JP", {
        dateStyle: "medium",
      }).formatToParts(date);
    }
    return new Intl.DateTimeFormat("ja-JP", params).formatToParts(date);
  },
  displayValue: function (value) {
    if ([null, undefined, ""].includes(value)) return "";
    return value;
  },
  showValue: function (
    value,
    kwargs = {
      prefix: "",
      suffix: "",
      includeZero: false,
      getProperty: "",
    }
  ) {
    // return "" if value is null, undefined, "" or 0 (if includeZero = true)
    if ([null, undefined, "", NaN].includes(value)) {
      return "";
    }
    if (kwargs["includeZero"]) {
      if ([0, "0"].includes(value)) {
        return "";
      }
    }
    // get value from object
    if (![null, undefined, "", []].includes(kwargs["getProperty"])) {
      if (Array.isArray(kwargs["getProperty"])) {
        kwargs["getProperty"].forEach((item) => {
          if ([null, undefined, ""].includes(value)) return "";
          value = value[item];
        });
      } else {
        value = value[kwargs["getProperty"]];
      }
    }
    if (kwargs["prefix"]) {
      value = kwargs["prefix"] + value;
    }
    if (kwargs["suffix"]) {
      value = value + kwargs["suffix"];
    }
    return value;
  },
  onlyNumberInput: function (event, oldValue = 0, decimal = false) {
    // only number input and (period if decimal = true)
    // @input="field=func.onlyNumberInput($event, field)"
    try {
      if (decimal) {
        return parseFloat(event.target.value);
      } else {
        return parseInt(event.target.value);
      }
    } catch (error) {
      return oldValue;
    }
  },
  convertAndRoundToMY: function (yenAmount, conversionRate = 10000) {
    return Math.ceil(yenAmount / conversionRate);
  },
  convertEnum: function (value, enumConst, labelToValue = false) {
    // const MANAGEMENT_TYPE = [
    //   { label: "", value: 0 },
    //   { label: "全部委託", value: 1 },
    //   { label: "一部委託", value: 2 },
    //   { label: "自主管理", value: 3 },
    // ];
    // convert value to label if labelToValue = false
    let obj = {};
    if (labelToValue) {
      obj = enumConst.find((item) => item.label === value);
    } else {
      obj = enumConst.find((item) => item.value === value);
    }
    if ([null, undefined, ""].includes(obj)) return "";
    return labelToValue ? obj.value : obj.label;
  },
  birthdayToAge(birthday) {
    if (!birthday) return;
    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },
  navigateBack(router, name, params = {}) {
    // router = this.$router
    let previousUrl = window.history.state.back;
    let goBackUrl = router.resolve({ name: name, params: params }).path;
    if (previousUrl && previousUrl.includes(goBackUrl)) {
      router.go(-1);
    } else {
      router.push({ name: name, params: params });
    }
  },
};
