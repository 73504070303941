const state = () => ({
  statusExport: false,
  actionExport: false,
});

const getters = {};

const actions = {
  setStatus({ commit }, isExport) {
    commit("setStatus", { status: isExport });
  },
  setAction({ commit }, isAction) {
    commit("setAction", { status: isAction });
  },
};

const mutations = {
  setStatus(state, { status }) {
    state.statusExport = status;
  },
  setAction(state, { status }) {
    state.actionExport = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
