import axios from "axios";
import { RECOMMEND_SCHELDULE_CALENDARS } from "./const.service.js";

const getRecommendScheduleCalendars = async () => {
  try {
    return axios.get(RECOMMEND_SCHELDULE_CALENDARS);
  } catch (error) {
    console.log(error);
  }
};
export { getRecommendScheduleCalendars };
