<template>
  <router-view></router-view>
  <BaseLoading />
  <ModalConfirmApi />
  <ModalAlert />
  <Aggregation />
</template>

<script>
import BaseLoading from "/src/components/utilitys/BaseLoading.vue";
import ModalConfirmApi from "/src/components/modals/ModalConfirmApi.vue";
import ModalAlert from "/src/components/modals/ModalAlert.vue";
import Aggregation from "/src/components/aggregation/Aggregation.vue";

export default {
  name: "App",
  components: {
    BaseLoading,
    ModalConfirmApi,
    ModalAlert,
    Aggregation,
  },
};
</script>
