<template>
  <div class="form-group">
    <div class="input-group date custom-inp-date">
      <VueDatePicker
        :id="id"
        v-model="date"
        :model-value="modelvalue"
        :enable-time-picker="false"
        :format="formatDate"
        :placeholder="placeholder"
        locale="ja"
        cancel-text="キャンセル"
        select-text="選択する"
        auto-apply
        :clearable="false"
        :disabled="disabled"
        :min-date="mindate"
        :max-date="maxdate"
        @update:model-value="setDate(date)">
        <template #input-icon>
          <i class="far fa-calendar"></i>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
  name: "InputDatePicker",
  components: {
    VueDatePicker,
  },
  props: {
    id: { type: String, default: "" },
    modelvalue: { type: String, default: "" },
    changedate: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false },
    typeformat: { type: String, default: "fe" },
    mindate: { type: String, default: null },
    maxdate: { type: String, default: null },
  },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    formatDate(date, type) {
      if (date == null) return "";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (type == "be") return [year, month, day].join("-");
      return [year, month, day].join("/");
    },
    setDate(value) {
      this.$emit("update:modelvalue", this.formatDate(value, this.typeformat));
      this.changedate();
    },
  },
};
</script>
