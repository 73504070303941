const state = () => ({
  users: [],
  headers: "admin",
  user_object: {
    role: null,
  },
});

const getters = {
  isDeleteActivate(state) {
    let UserEl = state.users.find((el) => el.isSelected);
    return typeof UserEl !== "undefined";
  },
  getHeaders(state) {
    return window.localStorage.getItem("authorization-headers");
  },
  getUserIdForDelete(state) {
    return state.users.filter((el) => el.isSelected).map((el) => el.user_id);
  },
  getUserObject(state) {
    if (state.user_object.role == null) {
      if (getCookie("authorization") != undefined) {
        state.user_object = decodeWebJsonToken(getCookie("authorization"));
        localStorage.setItem("user_object", JSON.stringify(state.user_object));
      } else if (localStorage.getItem("user_object") != null) {
        state.user_object = JSON.parse(localStorage.getItem("user_object"));
      }
    }
  },
};

const actions = {};

const mutations = {
  setUserLogin(state, user) {
    state.user_object = user;
  },
  setUserList(state, newUserList) {
    newUserList.forEach(function (element) {
      element["isSelected"] = false;
    });
    state.users = newUserList;
  },
  toggleUser(state, id) {
    state.users.forEach(function (element) {
      if (element.user_id === id) element.isSelected = !element.isSelected;
    });
  },
  setHeaders(state, headers) {
    window.localStorage.setItem("authorization-headers", headers);
  },
  deleteUserFromList(state, list_user_id) {
    state.users = state.users.filter(
      (el) => !list_user_id.includes(el.user_id)
    );
  },
};
function getCookie(name) {
  let cookie = document.cookie;
  let cookieArray = cookie.split("; ");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookieName = cookieArray[i].split("=");
    if (cookieName[0] === name) {
      return cookieName[1];
    }
  }
  return undefined;
}
function decodeWebJsonToken(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
