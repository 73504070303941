<template>
  <div :id="idshow" class="show-more-content">
    <div class="show-content" :style="{ 'max-height': setTop + 'px' }">
      <slot></slot>
    </div>
    <div class="show-button">
      <button class="btn btn-cus btn-blue-light" @click="showMore(idshow)">
        全ての項目を表示
      </button>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "ShowMore",
  props: {
    idshow: { type: String, default: "show-more-ct" },
    idAnchor: { type: String, default: "show-anchor" },
  },
  data() {
    return {
      setTop: 100,
    };
  },
  mounted() {
    setTimeout(() => {
      this.getOffset();
    }, 500);
  },
  updated() {
    this.getOffset();
  },
  methods: {
    showMore(idElement) {
      $("#" + idElement).addClass("active");
    },
    getOffset() {
      let ww = window.innerWidth;
      let splus = 93;
      if (ww < 575) splus = 135;
      this.setTop = document.getElementById(this.idAnchor).offsetTop + splus;
    },
  },
};
</script>
