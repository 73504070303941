<template>
  <div
    v-if="isConfirm"
    :id="id"
    class="modal fade custom-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-4 text-center">
            <div class="fs-16 my-4" style="white-space: pre-wrap">
              {{ message }}
            </div>
            <div class="text-center">
              <button
                class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
                data-dismiss="modal">
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "ModalConfirmApi",
  props: {
    id: {
      type: String,
      default: "confirmModal",
    },
  },
  computed: {
    ...mapState("confirmapi", ["isConfirm", "message"]),
  },
  watch: {
    isConfirm(newval) {
      let cmstore = this;
      if (newval) {
        setTimeout(() => {
          $("#confirmModal").modal("show");
          $("#confirmModal").on("hide.bs.modal", function () {
            cmstore.commitStore();
          });
        }, 10);
      }
    },
  },
  methods: {
    commitStore() {
      this.$store.commit("confirmapi/setConfirm", false);
    },
  },
};
</script>
