<template>
  <div
    :id="id"
    class="modal custom-modal fade"
    tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-4 text-center">
            <h5 class="fs-18">{{ title }}</h5>
            <div class="fs-16 my-4">
              {{ message }} <br />
              <span v-if="isquest">よろしいですか？</span>
            </div>
            <div class="text-center">
              <button
                class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
                data-dismiss="modal"
                @click="$emit('yes')">
                {{ btnyes }}
              </button>
              <button
                class="btn btn-cus btn-grey mx-2 w-125 fs-14"
                data-dismiss="modal"
                @click="$emit('no')">
                {{ btnno }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalConfirmSubmit",
  props: {
    id: { type: String, default: "confirmSubmit" },
    title: { type: String, default: "" },
    message: { type: String, default: "" },
    btnyes: { type: String, default: "は い" },
    btnno: { type: String, default: "いいえ" },
    isquest: { type: Boolean, default: true },
  },
};
</script>
