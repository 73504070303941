<template>
  <div class="my-4">
    <div class="font-weight-bold my-3 fs-20">物件概要</div>
    <ShowMoreLayout>
      <div class="row div-table mx-0">
        <div class="col-sm-4 div-td-1">物件名</div>
        <div class="col-sm-8 div-td-2">
          {{ data.name }} {{ data.buildingNumber }}
        </div>
        <div class="col-sm-4 div-td-1">所在地</div>
        <div class="col-sm-8 div-td-2">
          <span style="white-space: pre-wrap">
            {{ data.locationPrefecture }} {{ data.locationDistrict }}
            {{ data.locationDetail }}</span
          >
        </div>
        <div class="col-sm-4 div-td-1">
          <div class="h-100 d-flex align-items-center">交 通</div>
        </div>
        <div class="col-sm-8 div-td-2">
          <span
            v-if="
              checkNullEmptyZero(
                data.nearestStationRailway1,
                converIfAlltWhiteSpace(data.nearestStation1),
                data.walkingTonNearestStation1
              )
            ">
            {{
              interpolationStation(
                data.nearestStationRailway1,
                converIfAlltWhiteSpace(data.nearestStation1),
                data.walkingTonNearestStation1
              )
            }}
            <br />
          </span>
          <span
            v-if="
              checkNullEmptyZero(
                data.nearestStationRailway2,
                converIfAlltWhiteSpace(data.nearestStation2),
                data.walkingTonNearestStation2
              )
            ">
            {{
              interpolationStation(
                data.nearestStationRailway2,
                converIfAlltWhiteSpace(data.nearestStation2),
                data.walkingTonNearestStation2
              )
            }}
            <br />
          </span>
          <span
            v-if="
              checkNullEmptyZero(
                data.nearestStationRailway3,
                converIfAlltWhiteSpace(data.nearestStation3),
                data.walkingTonNearestStation3
              )
            ">
            {{
              interpolationStation(
                data.nearestStationRailway3,
                converIfAlltWhiteSpace(data.nearestStation3),
                data.walkingTonNearestStation3
              )
            }}
          </span>
        </div>
        <div
          v-if="data.price != null && data.price != 0"
          class="col-sm-4 div-td-1">
          価 格
        </div>
        <div
          v-if="data.price != null && data.price != 0"
          class="col-sm-8 div-td-2">
          {{ data.price ? func.formatNumber(data.price) + " 円（税込）" : "" }}
        </div>
        <div class="col-sm-4 div-td-1">間取り</div>
        <div class="col-sm-8 div-td-2">
          {{ data.layoutType?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">専有面積</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.area
              ? func.formatNumber(data.area, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
        </div>
        <div class="col-sm-4 div-td-1">バルコニー面積</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.balconyArea
              ? func.formatNumber(data.balconyArea, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
        </div>
        <div
          v-if="
            checkIsNull(data.areaOfPrivateGarden, data.usageFeeOfPrivateGarden)
          "
          class="col-sm-4 div-td-1">
          専用庭面積/
          <span class="d-none d-sm-block"></span>
          使用料
        </div>
        <div
          v-if="
            checkIsNull(data.areaOfPrivateGarden, data.usageFeeOfPrivateGarden)
          "
          class="col-sm-8 div-td-2">
          {{
            data.areaOfPrivateGarden
              ? func.formatNumber(data.areaOfPrivateGarden, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
          {{
            checkValidSf(data.areaOfPrivateGarden, data.usageFeeOfPrivateGarden)
          }}
          {{
            data.usageFeeOfPrivateGarden
              ? "月額 " +
                func.formatNumber(data.usageFeeOfPrivateGarden) +
                " 円"
              : ""
          }}
        </div>
        <div
          v-if="
            checkIsNull(
              data.areaOfPrivateTerrace,
              data.usageFeeOfPrivateTerrace
            )
          "
          class="col-sm-4 div-td-1">
          専用テラス面積/
          <span class="d-none d-sm-block"></span>
          使用料
        </div>
        <div
          v-if="
            checkIsNull(
              data.areaOfPrivateTerrace,
              data.usageFeeOfPrivateTerrace
            )
          "
          class="col-sm-8 div-td-2">
          {{
            data.areaOfPrivateTerrace
              ? func.formatNumber(data.areaOfPrivateTerrace, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
          {{
            checkValidSf(
              data.areaOfPrivateTerrace,
              data.usageFeeOfPrivateTerrace
            )
          }}
          {{
            data.usageFeeOfPrivateTerrace
              ? "月額 " +
                func.formatNumber(data.usageFeeOfPrivateTerrace) +
                " 円"
              : ""
          }}
        </div>
        <div
          v-if="checkIsNull(data.areaOfRoofBalcony, data.usageFeeOfRoofBalcony)"
          class="col-sm-4 div-td-1">
          ルーフバルコニー面積/
          <span class="d-none d-sm-block"></span>
          使用料
        </div>
        <div
          v-if="checkIsNull(data.areaOfRoofBalcony, data.usageFeeOfRoofBalcony)"
          class="col-sm-8 div-td-2">
          {{
            data.areaOfRoofBalcony
              ? func.formatNumber(data.areaOfRoofBalcony, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
          {{ checkValidSf(data.areaOfRoofBalcony, data.usageFeeOfRoofBalcony) }}
          {{
            data.usageFeeOfRoofBalcony
              ? "月額 " + func.formatNumber(data.usageFeeOfRoofBalcony) + " 円"
              : ""
          }}
        </div>
        <div class="col-sm-4 div-td-1">築年月</div>
        <div class="col-sm-8 div-td-2">
          {{ data.completionDate ? formatDate(data.completionDate) : "" }}
        </div>
        <div class="col-sm-4 div-td-1">引渡可能年月</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.deliveryDate && data.deliveryDate != ""
              ? convert_year_month(data.deliveryDate)
              : data.deliveryAvailableStatus
          }}
        </div>
        <div id="show-anchor" class="col-sm-4 div-td-1">所在階数</div>
        <div class="col-sm-8 div-td-2">
          {{ data.floorNumber ? data.floorNumber + "階/" : ""
          }}{{ data.numberOfFloors ? data.numberOfFloors + "階" : "" }}
        </div>
        <div class="col-sm-4 div-td-1">建物構造</div>
        <div class="col-sm-8 div-td-2">
          {{ data.structureAndScale?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">総戸数</div>
        <div class="col-sm-8 div-td-2">
          {{ data.numberOfHouses ? `${data.numberOfHouses}戸` : "-" }}
        </div>
        <div
          v-if="data.rightType && isAllWhitespace(data.rightType) == false"
          class="col-sm-4 div-td-1">
          権利形態
        </div>
        <div
          v-if="data.rightType && isAllWhitespace(data.rightType) == false"
          class="col-sm-8 div-td-2">
          {{ data.rightType }}
        </div>
        <div class="col-sm-4 div-td-1">分譲会社</div>
        <div class="col-sm-8 div-td-2">
          {{ data.saleCompany?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">施工会社</div>
        <div class="col-sm-8 div-td-2">
          {{ data.constructionCompany?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">管理会社</div>
        <div class="col-sm-8 div-td-2">
          {{ data.managementCompany?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">管理形態</div>
        <div class="col-sm-8 div-td-2">{{ data.managementType }}</div>
        <div class="col-sm-4 div-td-1">管理員の勤務形態</div>
        <div class="col-sm-8 div-td-2">{{ data.managerWorkingStyle }}</div>
        <div class="col-sm-4 div-td-1">管理費</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.managementFee
              ? func.formatNumber(data.managementFee) + " 円／月"
              : ""
          }}
        </div>
        <div class="col-sm-4 div-td-1">修繕積立金</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.repairReserveFee
              ? func.formatNumber(data.repairReserveFee) + " 円／月"
              : ""
          }}
        </div>
        <div class="col-sm-4 div-td-1">その他費用</div>
        <div class="col-sm-8 div-td-2">
          <span v-if="checkNullEmpty(data.otherFee, data.otherFeeNote)">
            {{
              data.otherFee
                ? func.formatNumber(data.otherFee) + "（円/月）/内訳 : "
                : "-（円/月）/内訳 : "
            }}</span
          >
          <span
            v-if="checkNullEmpty(data.otherFee, data.otherFeeNote)"
            style="white-space: pre-wrap"
            >{{
              checkNullEmpty(data.otherFeeNote, data.otherFeeNote)
                ? data.otherFeeNote
                : "-"
            }}</span
          >
          <span v-if="checkNullEmpty(data.otherFee, data.otherFeeNote) == false"
            >-</span
          >
        </div>
        <div
          v-if="checkNullEmpty(data.parkingLotStatus, data.parkingLotNote)"
          class="col-sm-4 div-td-1">
          駐車場
        </div>
        <div
          v-if="checkNullEmpty(data.parkingLotStatus, data.parkingLotNote)"
          class="col-sm-8 div-td-2">
          {{ data.parkingLotStatus }}
          {{ data.parkingLotStatus && data.parkingLotNote ? "/" : "" }}
          <span style="white-space: pre-wrap">{{ data.parkingLotNote }}</span>
        </div>
        <div
          v-if="
            data.currentSituation &&
            isAllWhitespace(data.currentSituation) == false
          "
          class="col-sm-4 div-td-1">
          <span>現 況</span>
        </div>
        <div
          v-if="
            data.currentSituation &&
            isAllWhitespace(data.currentSituation) == false
          "
          class="col-sm-8 div-td-2">
          {{ data.currentSituation }}
        </div>
        <div class="col-sm-4 div-td-1">取引形態</div>
        <div class="col-sm-8 div-td-2">{{ data.tradeType }}</div>
        <div class="col-sm-4 div-td-1">セールスポイント</div>
        <div class="col-sm-8 div-td-2">
          {{ checkNullEmpty(data.salesPoint) ? data.salesPoint : "-" }}
        </div>
        <div class="col-sm-4 div-td-1">備 考</div>
        <div class="col-sm-8 div-td-2">
          <span style="white-space: pre-wrap">{{
            data.propertyNote == null ||
            converIfAlltWhiteSpace(data.propertyNote) == null
              ? "-"
              : data.propertyNote
          }}</span>
        </div>
        <div class="col-sm-4 div-td-1">情報更新日</div>
        <div class="col-sm-8 div-td-2">
          {{ func.formatDate(new Date(), { dateStyle: "long" }) }}
        </div>
      </div>
    </ShowMoreLayout>
    <p class="mt-3 fs-16">
      ※上記表示物件は、掲載中に成約済みとなる場合があります。<br />※次回更新予定日は{{
        func.formatDate(getFutureDate(), { dateStyle: "long" })
      }}です。
    </p>
  </div>
</template>

<script>
import {
  checkValidSf,
  checkIsNull,
} from "/src/components/recommend-properties/commons/utils/format.js";
import { interpolationStation } from "/src/components/utilitys/utility.js";
import ShowMoreLayout from "/src/components/layouts/ShowMoreLayout.vue";

export default {
  components: { ShowMoreLayout },
  props: {
    data: { type: Object, default: null },
    schedule: { type: Object, default: null },
  },
  data() {
    return { checkValidSf, interpolationStation, checkIsNull };
  },
  methods: {
    formatDate(input) {
      const [year, month] = input.split("-");
      return `${year}年${month}月`;
    },
    getFutureDate() {
      var today = new Date();
      var futureDate = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
      return futureDate;
    },
    convert_year_month(input_str) {
      if (input_str.length === 6 && /^\d+$/.test(input_str)) {
        return input_str.slice(0, 4) + "年" + input_str.slice(4) + "月";
      } else if (input_str.length === 7 && /^\d{4}-\d{2}$/.test(input_str)) {
        return input_str.slice(0, 4) + "年" + input_str.slice(5) + "月";
      } else {
        return input_str;
      }
    },
    isAllWhitespace(str) {
      if (str == null) {
        return str;
      }
      return str.trim() == "";
    },
    converIfAlltWhiteSpace(data) {
      if (data != null && data.trim() == "") {
        return null;
      } else {
        return data;
      }
    },
    checkNullEmpty(a, b) {
      if (
        (a == null ||
          (a != null && typeof a == "string" && a.trim() == "") ||
          a == 0) &&
        (b == null ||
          (b != null && typeof b == "string" && b.trim() == "") ||
          b == 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkNullEmptyZero(a, b, c) {
      if (
        (a != 0 && a != "" && a != null) ||
        (b != 0 && b != "" && b != null) ||
        (c != 0 && c != "" && c != null)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
