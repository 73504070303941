import axios from "axios";
import { AGGREGATE_API, AGGREGATE_API_V2 } from "./const.service.js";

const submitAggregate = async (body) => {
  try {
    const response = await axios.post(AGGREGATE_API, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const submitAggregatev2 = async (body) => {
  try {
    const response = await axios.post(AGGREGATE_API_V2, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export { submitAggregate, submitAggregatev2 };
