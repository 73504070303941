const layoutType = {
  0: "初期値、利用しない",
  1: "ワンルーム",
  2: "1K",
  3: "1DK",
  4: "1LDK",
  5: "1LDK+S",
  6: "1LDK+N",
  7: "2LDK",
  8: "2LDK+S",
  9: "2LDK+N",
  10: "3LDK",
  11: "3LDK+S",
  12: "3LDK+N",
  13: "4LDK",
  14: "4LDK+S",
  15: "4LDK+N",
  16: "5LDK以上",
};

const layoutTypeSalesForce = {
  10: "1R",
  11: "1K",
  12: "1DK",
  13: "1LK",
  14: "1LDK",
  15: "1SK",
  16: "1SDK",
  17: "1SLK",
  18: "1SLDK",
  20: "2R",
  21: "2K",
  22: "2DK",
  23: "2LK",
  24: "2LDK",
  25: "2SK",
  26: "2SDK",
  27: "2SLK",
  28: "2SLDK",
  30: "3R",
  31: "3K",
  32: "3DK",
  33: "3LK",
  34: "3LDK",
  35: "3SK",
  36: "3SDK",
  37: "3SLK",
  38: "3SLDK",
  40: " 4R",
  41: "4K",
  42: "4DK",
  43: "4LK",
  44: "4LDK",
  45: "4SK",
  46: "4SDK",
  47: "4SLK",
  48: "4SLDK",
  127: "5R以上",
};

const managementType = {
  1: "全部委託",
  2: "一部委託",
  3: "自主管理",
};

const managerWorkingStyle = {
  1: "常勤",
  2: "日勤",
  3: "巡回",
  4: "管理員なし",
};

const parkingLotStatus = {
  0: "不明",
  1: "空き有り",
  2: "空き無し",
};

export {
  layoutType,
  layoutTypeSalesForce,
  managementType,
  managerWorkingStyle,
  parkingLotStatus,
};
