<template>
  <div class="my-4">
    <div class="font-weight-bold my-3 fs-20">物件概要</div>
    <ShowMoreLayout>
      <div class="row div-table mx-0">
        <div class="col-sm-4 div-td-1">物件名</div>
        <div class="col-sm-8 div-td-2">{{ data.name }}</div>
        <div class="col-sm-4 div-td-1">所在地</div>
        <div class="col-sm-8 div-td-2">
          {{ data.locationPrefecture }} {{ data.locationDistrict }}
          {{ data.locationDetail }}
        </div>
        <div class="col-sm-4 div-td-1">
          <div class="h-100 d-flex align-items-center">交 通</div>
        </div>
        <div class="col-sm-8 div-td-2">
          <span
            v-if="
              data.nearestStationRailway1 ||
              data.nearestStation1 ||
              data.walkingTonNearestStation1
            ">
            {{
              interpolationStation(
                data.nearestStationRailway1,
                data.nearestStation1,
                data.walkingTonNearestStation1
              )
            }}
            <br />
          </span>
          <span
            v-if="
              data.nearestStationRailway2 ||
              data.nearestStation2 ||
              data.walkingTonNearestStation2
            ">
            {{
              interpolationStation(
                data.nearestStationRailway2,
                data.nearestStation2,
                data.walkingTonNearestStation2
              )
            }}
            <br />
          </span>
          <span
            v-if="
              data.nearestStationRailway3 ||
              data.nearestStation3 ||
              data.walkingTonNearestStation3
            ">
            {{
              interpolationStation(
                data.nearestStationRailway3,
                data.nearestStation3,
                data.walkingTonNearestStation3
              )
            }}
          </span>
        </div>
        <div
          v-if="data.price != null && data.price != 0"
          class="col-sm-4 div-td-1">
          <span>価 格</span>
        </div>
        <div
          v-if="data.price != null && data.price != 0"
          class="col-sm-8 div-td-2">
          {{ data.price ? func.formatNumber(data.price) + " 円（税込）" : "" }}
        </div>
        <div class="col-sm-4 div-td-1">間取り</div>
        <div class="col-sm-8 div-td-2">
          {{ data.layoutType?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">専有面積</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.area
              ? func.formatNumber(data.area, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
        </div>
        <div class="col-sm-4 div-td-1">バルコニー面積</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.balconyArea === null || data.balconyArea === 0
              ? "-"
              : func.formatNumber(data.balconyArea, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
          }}
        </div>
        <div
          v-if="
            checkIsNull(data.areaOfPrivateGarden, data.usageFeeOfPrivateGarden)
          "
          class="col-sm-4 div-td-1">
          専用庭面積、
          <span class="d-none d-sm-block"></span>
          使用料
        </div>
        <div
          v-if="
            checkIsNull(data.areaOfPrivateGarden, data.usageFeeOfPrivateGarden)
          "
          class="col-sm-8 div-td-2">
          {{
            data.areaOfPrivateGarden
              ? func.formatNumber(data.areaOfPrivateGarden, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
          {{
            checkValid(data.areaOfPrivateGarden, data.usageFeeOfPrivateGarden)
          }}
          {{
            data.usageFeeOfPrivateGarden
              ? "月額 " +
                func.formatNumber(data.usageFeeOfPrivateGarden) +
                " 円"
              : ""
          }}
        </div>
        <div
          v-if="
            checkIsNull(
              data.areaOfPrivateTerrace,
              data.usageFeeOfPrivateTerrace
            )
          "
          class="col-sm-4 div-td-1">
          専用テラス面積、
          <span class="d-none d-sm-block"></span>
          使用料
        </div>
        <div
          v-if="
            checkIsNull(
              data.areaOfPrivateTerrace,
              data.usageFeeOfPrivateTerrace
            )
          "
          class="col-sm-8 div-td-2">
          {{
            data.areaOfPrivateTerrace
              ? func.formatNumber(data.areaOfPrivateTerrace, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
          {{
            checkValid(data.areaOfPrivateTerrace, data.usageFeeOfPrivateTerrace)
          }}
          {{
            data.usageFeeOfPrivateTerrace
              ? "月額 " +
                func.formatNumber(data.usageFeeOfPrivateTerrace) +
                " 円"
              : ""
          }}
        </div>
        <div
          v-if="checkIsNull(data.areaOfRoofBalcony, data.usageFeeOfRoofBalcony)"
          class="col-sm-4 div-td-1">
          ルーフバルコニー面積、使用料
        </div>
        <div
          v-if="checkIsNull(data.areaOfRoofBalcony, data.usageFeeOfRoofBalcony)"
          class="col-sm-8 div-td-2">
          {{
            data.areaOfRoofBalcony
              ? func.formatNumber(data.areaOfRoofBalcony, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
          {{ checkValid(data.areaOfRoofBalcony, data.usageFeeOfRoofBalcony) }}
          {{
            data.usageFeeOfRoofBalcony
              ? "月額 " + func.formatNumber(data.usageFeeOfRoofBalcony) + "円"
              : ""
          }}
        </div>
        <div class="col-sm-4 div-td-1">竣工日</div>
        <div class="col-sm-8 div-td-2">
          {{ data.completionDate ? formatDate(data.completionDate) : "" }}
        </div>
        <div class="col-sm-4 div-td-1">引渡可能年月</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.deliveryDate
              ? formatDate(data.deliveryDate, true)
              : data.deliveryAvailableStatus
          }}
        </div>
        <div id="show-anchor" class="col-sm-4 div-td-1">所在階数</div>
        <div class="col-sm-8 div-td-2">
          {{ data.floorNumber ? data.floorNumber + "階" : "" }}
        </div>
        <div class="col-sm-4 div-td-1">構造・規模</div>
        <div class="col-sm-8 div-td-2">
          {{ data.structureAndScale?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">総戸数</div>
        <div class="col-sm-8 div-td-2">
          {{ data.numberOfHouses ? `${data.numberOfHouses}戸` : "-" }}
        </div>
        <div class="col-sm-4 div-td-1">敷地面積</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.siteArea
              ? func.formatNumber(data.siteArea, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ㎡"
              : ""
          }}
        </div>
        <div class="col-sm-4 div-td-1">用途地域</div>
        <div class="col-sm-8 div-td-2">{{ data.useDistrict }}</div>
        <div class="col-sm-4 div-td-1">建築確認番号</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.buildingConfirmationNumber
              ? data.buildingConfirmationNumber +
                " " +
                formatDateOfBuildingConfirmation(
                  func.formatDate(data.acquisitionDateOfBuildingConfirmation, {
                    dateStyle: "long",
                  })
                )
              : formatDateOfBuildingConfirmation(
                  func.formatDate(data.acquisitionDateOfBuildingConfirmation, {
                    dateStyle: "long",
                  })
                )
          }}
        </div>
        <div class="col-sm-4 div-td-1">権利形態</div>
        <div class="col-sm-8 div-td-2">{{ data.rightType }}</div>
        <div class="col-sm-4 div-td-1">
          <div class="h-100 d-flex align-items-center">
            <span v-if="isOther">分譲会社</span>
            <span v-else>売 主</span>
          </div>
        </div>
        <div class="col-sm-8 div-td-2">
          <span style="white-space: pre-wrap">{{ saleCompanies }}</span>
        </div>
        <div class="col-sm-4 div-td-1">施工会社</div>
        <div class="col-sm-8 div-td-2">
          {{ data.constructionCompany?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">管理会社</div>
        <div class="col-sm-8 div-td-2">
          {{ data.managementCompany?.trim() || "-" }}
        </div>
        <div class="col-sm-4 div-td-1">管理形態</div>
        <div class="col-sm-8 div-td-2">
          {{ data.managementType }}
        </div>
        <div class="col-sm-4 div-td-1">管理員の勤務形態</div>
        <div class="col-sm-8 div-td-2">{{ data.managerWorkingStyle }}</div>
        <div class="col-sm-4 div-td-1">管理費</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.managementFee === null || data.managementFee === 0
              ? "未定"
              : func.formatNumber(data.managementFee) + " 円／月"
          }}
        </div>
        <div class="col-sm-4 div-td-1">修繕積立金</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.repairReserveFee === null || data.repairReserveFee === 0
              ? "未定"
              : func.formatNumber(data.repairReserveFee) + " 円／月"
          }}
        </div>
        <div class="col-sm-4 div-td-1">駐車場台数</div>
        <div class="col-sm-8 div-td-2">
          {{
            data.parkingLotCount
              ? func.formatNumber(data.parkingLotCount) + "台"
              : data.parkingLotCount == 0
              ? "0台"
              : ""
          }}
        </div>
        <template v-if="isOther">
          <div class="col-sm-4 div-td-1">駐車場空き有無</div>
          <div class="col-sm-8 div-td-2">
            {{ data.parkingLotStatus ? data.parkingLotStatus : "" }}
          </div>
        </template>
        <div class="col-sm-4 div-td-1">駐車場備考</div>
        <div class="col-sm-8 div-td-2">
          <span style="white-space: pre-wrap">{{ data.parkingLotNote }}</span>
        </div>
        <div class="col-sm-4 div-td-1">取引態様</div>
        <div class="col-sm-8 div-td-2">{{ data.tradeType }}</div>
        <div class="col-sm-4 div-td-1">備 考</div>
        <div class="col-sm-8 div-td-2">
          <span v-if="data.propertyNote" style="white-space: pre-wrap">
            {{ data.propertyNote }} <br />
          </span>
          <span v-if="data.blockNote" style="white-space: pre-wrap">
            {{ data.blockNote }}
          </span>
        </div>
        <div class="col-sm-4 div-td-1">情報公開日</div>
        <div class="col-sm-8 div-td-2">
          {{
            schedule.release_date
              ? func.formatDate(schedule.release_date, { dateStyle: "long" })
              : "未定"
          }}
        </div>
        <div class="col-sm-4 div-td-1">取引条件の有効期限</div>
        <div class="col-sm-8 div-td-2">
          {{
            schedule.expiry_date_before_one_day
              ? func.formatDate(schedule.expiry_date_before_one_day, {
                  dateStyle: "long",
                })
              : "未定"
          }}
        </div>
      </div>
    </ShowMoreLayout>
    <p class="mt-3 fs-16">
      ※上記表示物件は、掲載中に成約済みとなる場合があります。<br />※次回更新予定日は{{
        schedule.expiry_date
          ? func.formatDate(schedule.expiry_date, {
              dateStyle: "long",
            })
          : "未定"
      }}です。
    </p>
  </div>
</template>

<script>
import { checkValid } from "/src/components/recommend-properties/commons/utils/format.js";
import { interpolationStation } from "/src/components/utilitys/utility.js";
import {
  formatDate,
  checkIsNull,
  formatDateOfBuildingConfirmation,
} from "/src/components/recommend-properties/commons/utils/format.js";
import ShowMoreLayout from "/src/components/layouts/ShowMoreLayout.vue";

export default {
  components: { ShowMoreLayout },
  props: {
    data: { type: Object, default: null },
    schedule: { type: Object, default: null },
    isOther: { type: Boolean, default: false },
  },
  data() {
    return {
      formatDate,
      checkValid,
      interpolationStation,
      checkIsNull,
      formatDateOfBuildingConfirmation,
    };
  },
  computed: {
    saleCompanies() {
      const sellers = [
        this.data.sellerOwner1,
        this.data.sellerOwner2,
        this.data.sellerOwner3,
        this.data.sellerOwner4,
        this.data.sellerOwner5,
      ]
        .filter((el) => el?.sales_company_name)
        .map((el) =>
          el.sales_company_information
            ? `${el.sales_company_name}\n${el.sales_company_information}`
            : el.sales_company_name
        )
        .join("\n\n");

      return sellers.trim() || "-";
    },
  },
};
</script>
