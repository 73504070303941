import {
  layoutType,
  managementType,
  managerWorkingStyle,
  parkingLotStatus,
} from "/src/components/recommend-properties/commons/constans.js";

function getNewInHouseHeader(data, showData) {
  showData.name = data.property_block.property.name;
  showData.price = data.property_block.price;
  showData.blockClassification = data.property_block.block_classification;
  if (data.property_block.layout_type) {
    showData.layoutType = layoutType[data.property_block.layout_type];
  }
  showData.area = data.property_block.area;
}

function getHouseImage(data, showData) {
  showData.appearanceImageLink =
    data.property_block.property.appearance_image_link;
  showData.blockImageLink = data.property_block.block_image_link;
}

function getNewInHouseIcon(data, showData) {
  const walkingToStation1 =
    data.property_block.property.walking_to_nearest_station_1;
  const walkingToStation2 =
    data.property_block.property.walking_to_nearest_station_2;
  const walkingToStation3 =
    data.property_block.property.walking_to_nearest_station_3;
  const nearestStationRailway1 =
    data.property_block.property.nearest_station_railway_1;
  const nearestStationRailway2 =
    data.property_block.property.nearest_station_railway_2;
  const nearestStationRailway3 =
    data.property_block.property.nearest_station_railway_3;
  if (
    (walkingToStation1 !== null &&
      walkingToStation1 > 0 &&
      walkingToStation1 <= 5) ||
    (walkingToStation2 !== null &&
      walkingToStation2 > 0 &&
      walkingToStation2 <= 5) ||
    (walkingToStation3 !== null &&
      walkingToStation3 > 0 &&
      walkingToStation3 <= 5)
  ) {
    showData.iconWalkingToNearestStation = true;
  }
  if (
    (nearestStationRailway1 !== null && nearestStationRailway2 !== null) ||
    (nearestStationRailway1 !== null && nearestStationRailway3 !== null) ||
    (nearestStationRailway2 !== null && nearestStationRailway3 !== null)
  ) {
    showData.iconNearestStationRailway = true;
  }
  showData.iconConciergeService =
    data.property_block.property.concierge_service;
  showData.iconAllDayMannedManagement =
    data.property_block.property.all_day_manned_management;
  showData.iconFurnished = data.property_block.furnished;
  showData.iconTopFloor = data.property_block.top_floor;
  if (
    data.property_block.private_garden_exist_flag == 1 ||
    data.property_block.private_terrace_exist_flag == 1
  ) {
    showData.iconPrivateExistFlag = true;
  }
  showData.iconRoofBalconyExistFlag =
    data.property_block.roof_balcony_exist_flag;
}

function getNewInHouseTable(data, showData) {
  showData.name = data.property_block.property.name;
  showData.locationPrefecture =
    data.property_block.property.location_prefecture;
  showData.locationDistrict = data.property_block.property.location_district;
  showData.locationDetail = data.property_block.property.location_detail;

  showData.nearestStationRailway1 =
    data.property_block.property.nearest_station_railway_1;
  showData.nearestStationRailway2 =
    data.property_block.property.nearest_station_railway_2;
  showData.nearestStationRailway3 =
    data.property_block.property.nearest_station_railway_3;
  showData.nearestStation1 = data.property_block.property.nearest_station_1;
  showData.nearestStation2 = data.property_block.property.nearest_station_2;
  showData.nearestStation3 = data.property_block.property.nearest_station_3;
  showData.walkingTonNearestStation1 =
    data.property_block.property.walking_to_nearest_station_1;
  showData.walkingTonNearestStation2 =
    data.property_block.property.walking_to_nearest_station_2;
  showData.walkingTonNearestStation3 =
    data.property_block.property.walking_to_nearest_station_3;

  showData.price = data.property_block.price;
  showData.area = data.property_block.area;
  showData.balconyArea = data.property_block.balcony_area;
  showData.areaOfPrivateGarden = data.property_block.area_of_private_garden;
  showData.usageFeeOfPrivateGarden =
    data.property_block.usage_fee_of_private_garden;
  showData.areaOfPrivateTerrace = data.property_block.area_of_private_terrace;
  showData.usageFeeOfPrivateTerrace =
    data.property_block.usage_fee_of_private_terrace;
  showData.areaOfRoofBalcony = data.property_block.area_of_roof_balcony;
  showData.usageFeeOfRoofBalcony =
    data.property_block.usage_fee_of_roof_balcony;
  showData.completionDate = data.property_block.property.completion_date;
  showData.deliveryDate = data.property_block.delivery_date;
  showData.floorNumber = data.property_block.floor_number;
  showData.structureAndScale = data.property_block.property.structure_and_scale;
  showData.numberOfFloors = data.property_block.property.number_of_floors;
  showData.numberOfHouses = data.property_block.property.number_of_houses;
  showData.siteArea = data.property_block.property.site_area;
  showData.useDistrict = data.property_block.property.use_district;
  showData.buildingConfirmationNumber =
    data.property_block.property.building_confirmation_number;
  showData.rightType = data.property_block.property.right_type;
  if (data.property_block.property.seller_owner_1_) {
    showData.sellerOwner1 = data.property_block.property.seller_owner_1_;
  }
  if (data.property_block.property.seller_owner_2_) {
    showData.sellerOwner2 = data.property_block.property.seller_owner_2_;
  }

  if (data.property_block.property.seller_owner_3_) {
    showData.sellerOwner3 = data.property_block.property.seller_owner_3_;
  }

  if (data.property_block.property.seller_owner_4_) {
    showData.sellerOwner4 = data.property_block.property.seller_owner_4_;
  }

  if (data.property_block.property.seller_owner_5_) {
    showData.sellerOwner5 = data.property_block.property.seller_owner_5_;
  }
  showData.constructionCompany =
    data.property_block.property.construction_company;
  showData.managementCompany = data.property_block.property.management_company;
  showData.managementType =
    managementType[data.property_block.property.management_type];
  showData.managerWorkingStyle =
    managerWorkingStyle[data.property_block.property.manager_working_style];
  showData.managementFee = data.property_block.management_fee;
  showData.repairReserveFee = data.property_block.repair_reserve_fee;
  showData.parkingLotCount = data.property_block.property.parking_lot_count;
  showData.parkingLotNote = data.property_block.property.parking_lot_note;
  showData.parkingLotStatus =
    parkingLotStatus[data.property_block.property.parking_lot_status];
  showData.tradeType = data.property_block.property.trade_type;
  showData.propertyNote = data.property_block.property.property_note;
  showData.updatedAt = data.property_block.property.updated_at;
  showData.acquisitionDateOfBuildingConfirmation =
    data.property_block.property.acquisition_date_of_building_confirmation;
  showData.blockNote = data.property_block.block_note;
}

function getNewInHouseMapImage(data, showData) {
  showData.guideMapImageLink =
    data.property_block.property.guide_map_image_link;
}

function getTourAvailable(data, showData) {
  showData.isShowButton = data.property_block.property.tour_available_flag;
}

export {
  getNewInHouseHeader,
  getHouseImage,
  getNewInHouseIcon,
  getNewInHouseTable,
  getNewInHouseMapImage,
  getTourAvailable,
};
