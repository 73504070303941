import { createStore } from "vuex";

import actions from "./actions";
import mutations from "./mutations";
import user from "./modules/user";
import loading from "./modules/loading";
import confirmapi from "./modules/confirm-api";
import alertMessage from "./modules/alert-message";
import aggregation from "./modules/aggregation";
import properties from "./modules/properties";

const store = createStore({
  modules: {
    user,
    loading,
    confirmapi,
    alertMessage,
    aggregation,
    properties,
  },
  mutations,
  actions,
});

export default store;
