const state = () => ({
  isDisplay: false,
  message: "",
});

const getters = {};

const actions = {
  async showAlert({ commit }, message) {
    // Show alert
    commit("setAlert", { isDisplay: true, message: message });
    // Auto close alert after 2s
    return new Promise((resolve) => {
      setTimeout(() => {
        commit("setAlert", { isDisplay: false, message: "" });
        resolve(); // Resolve the Promise when the setTimeout completes
      }, 2000);
    });
  },
  async showAlertV2({ commit }, { message, timeout = 2000 }) {
    // Show alert
    commit("setAlert", { isDisplay: true, message: message });
    // Auto close alert after timeout
    return new Promise((resolve) => {
      setTimeout(() => {
        commit("setAlert", { isDisplay: false, message: "" });
        resolve(); // Resolve the Promise when the setTimeout completes
      }, timeout);
    });
  },
};

const mutations = {
  setAlert(state, { isDisplay, message }) {
    state.isDisplay = isDisplay;
    state.message = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
