<template>
  <div class="text-center my-4">
    <img
      :src="IMAGE_PROPERTIES + data.appearanceImageLink"
      alt="img"
      class="img-fluid" />
  </div>
  <div class="text-center my-4">
    <img
      :src="IMAGE_PROPERTIES + data.blockImageLink"
      alt="img"
      class="img-fluid" />
  </div>
</template>

<script>
import { IMAGE_PROPERTIES } from "/src/constants.js";
export default {
  props: {
    data: { type: Object, default: () => {} },
  },
  data() {
    return { IMAGE_PROPERTIES };
  },
};
</script>
