const state = () => ({
  isConfirm: false,
  message: "",
});

const getters = {};

const actions = {};

const mutations = {
  setConfirm(state, { status, mes }) {
    state.isConfirm = status;
    state.message = mes;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
