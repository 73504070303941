<template>
  <NewInHouseCustomer
    v-if="renderNewInHouse"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule" />
  <OldInHouseCustomer
    v-if="renderOldInHouse"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule"
    :building-number="buildingNumber" />
  <NewOtherCompanyCustomer
    v-if="renderNewOtherCompany"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule" />
</template>

<script>
import { getUuid } from "/src/components/recommend-properties/commons/utils/format.js";
import {
  getPreviousDay,
  returnTextButton,
} from "/src/components/recommend-properties/commons/utils/format.js";
import { getRecommendScheduleCalendars } from "/src/service/recommend-schedule-calendar.js";
import { PROPERTY_TYPE, ORIGINAL_LOCATION } from "/src/constants.js";
import {
  getRecommendProperties,
  inCreasePageView,
} from "/src/service/recommend.service.js";
import OldInHouseCustomer from "/src/components/recommend-properties/OldInHouseCustomer.vue";
import NewOtherCompanyCustomer from "/src/components/recommend-properties/NewOtherCompanyCustomer.vue";
import NewInHouseCustomer from "/src/components/recommend-properties/NewInHouseCustomer.vue";
import { showData } from "/src/helpers/info-properties.js";
import {
  getOldInHouseHeader,
  getOldInHouseImage,
  getOldInHouseIcon,
  getOldInHouseTable,
} from "/src/components/recommend-properties/commons/utils/oldInHouse.js";
import {
  getNewInHouseHeader,
  getHouseImage,
  getNewInHouseIcon,
  getNewInHouseTable,
  getNewInHouseMapImage,
  getTourAvailable,
} from "/src/components/recommend-properties/commons/utils/newInHouse.js";

export default {
  components: {
    NewInHouseCustomer,
    NewOtherCompanyCustomer,
    OldInHouseCustomer,
  },
  data() {
    return {
      getUuid,
      textButton: "",
      showData,
      PROPERTY_TYPE,
      ORIGINAL_LOCATION,
      renderNewInHouse: false,
      renderOldInHouse: false,
      renderNewOtherCompany: false,
      buildingNumber: null,
      schedule: {
        release_date: "",
        expiry_date: "",
        expiry_date_before_one_day: "",
      },
    };
  },
  created() {
    const uuid = this.getUuid();
    inCreasePageView(uuid);
    getRecommendProperties(uuid).then((data) => {
      try {
        if (data.data.property_table_type == PROPERTY_TYPE.PROPERTY) {
          this.textButton = returnTextButton(
            data.data.property_block.block_classification,
            data.data.property_block.property.completion_date
          );
          if (
            data.data.property.location_of_original_data ==
            ORIGINAL_LOCATION.DYNAMIC
          ) {
            this.renderNewInHouse = true;
          } else if (
            data.data.property.location_of_original_data ==
            ORIGINAL_LOCATION.OTHER
          ) {
            this.renderNewOtherCompany = true;
          }
          getNewInHouseHeader(data.data, this.showData);
          getHouseImage(data.data, this.showData);
          getNewInHouseIcon(data.data, this.showData);
          getNewInHouseTable(data.data, this.showData);
          getNewInHouseMapImage(data.data, this.showData);
          getTourAvailable(data.data, this.showData);
        } else {
          this.buildingNumber = data.data.salesforce_property.building_number;
          this.textButton = returnTextButton(
            data.data.salesforce_property.block_classification,
            data.data.salesforce_property.completion_year_and_month
          );
          this.renderOldInHouse = true;
          getOldInHouseHeader(data.data, this.showData);
          getOldInHouseImage(data.data, this.showData);
          getOldInHouseIcon(data.data, this.showData);
          getOldInHouseTable(data.data, this.showData);
        }
      } catch {
        if (data.response.status != 200) {
          this.$router.push("/error404");
        }
      }
    });
    getRecommendScheduleCalendars().then((data) => {
      this.schedule.release_date = data.data.release_date;
      this.schedule.expiry_date = data.data.expiry_date;
      this.schedule.expiry_date_before_one_day = getPreviousDay(
        data.data.expiry_date
      );
    });
  },
};
</script>
