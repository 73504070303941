<template>
  <div
    v-if="
      !(
        data.iconWalkingToNearestStation === false &&
        data.iconNearestStationRailway === false &&
        data.iconConciergeService === false &&
        data.iconAllDayMannedManagement === false &&
        data.iconFurnished === false &&
        data.iconTopFloor === false &&
        data.iconPrivateExistFlag === false &&
        data.iconRoofBalconyExistFlag === false &&
        data.iconYearAndMonth === false
      )
    "
    class="my-4">
    <div class="custom-callout">物件特徴</div>
    <div class="row text-center">
      <div v-if="data.iconWalkingToNearestStation" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-1.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconNearestStationRailway" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-2.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconConciergeService" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-3.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconAllDayMannedManagement" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-4.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconFurnished" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-5.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconTopFloor" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-6.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconPrivateExistFlag" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-7.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconRoofBalconyExistFlag" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-8.png"
          alt="img"
          class="img-fluid" />
      </div>
      <div v-if="data.iconYearAndMonth" class="col-4 col-sm-3 my-2">
        <img
          src="@/assets/img/property-feat/feat-9.png"
          alt="img"
          class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null },
  },
};
</script>
