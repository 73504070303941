const state = () => ({
  count: 0,
  isLoading: false,
});

const getters = {};

const actions = {};

const mutations = {
  setLoading(state, val) {
    if (val === true) {
      state.count++;
      state.isLoading = val;
    }
    if (val === false) {
      state.count--;
      if (state.count < 0) {
        state.count = 0;
      }
      if (state.count === 0) {
        state.isLoading = val;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
