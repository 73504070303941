<template>
  <div class="my-4">
    <div class="custom-callout">現地案内図</div>
    <div class="my-3">
      <img
        :src="IMAGE_PROPERTIES + data.guideMapImageLink"
        alt="img"
        class="img-fluid" />
    </div>
  </div>
</template>

<script>
import { IMAGE_PROPERTIES } from "/src/constants.js";
export default {
  props: {
    data: { type: Object, default: () => {} },
  },
  data() {
    return { IMAGE_PROPERTIES };
  },
};
</script>
