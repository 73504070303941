<template>
  <div v-if="isDisplay" id="custom-alert" aria-hidden="true">
    <div class="alert alert-info text-center" role="alert">
      <div v-if="!newLineMessage">
        {{ message }}
      </div>
      <div v-else>
        <div v-for="(line, index) in message.split('\n')" :key="index">
          {{ line }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("alertMessage", ["isDisplay", "message"]),
    newLineMessage() {
      if (this.message === undefined) return false;
      return this.message.includes("\n");
    },
  },
};
</script>
