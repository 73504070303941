<template>
  <div class="row my-3">
    <div class="col-12 font-weight-bold fs-20">
      <div class="text-blue3 my-2">
        {{ data.name }} {{ buildingNumber ? buildingNumber : "" }}
      </div>
      <div class="text-pink2 font-weight-bold">
        <span v-if="data.price" class="fs-32">{{
          data.price
            ? func.formatNumber(func.convertAndRoundToMY(data.price))
            : ""
        }}</span>
        <span v-if="data.price" class="fs-16 mr-4">
          {{ data.price ? "万円（税込）" : "" }}</span
        >
        <span
          v-if="data.area || data.layoutType"
          class="text-black fs-20 d-block d-sm-inline-block mt-2"
          >{{ data.layoutType ? data.layoutType : ""
          }}{{ checkValues(data.layoutType, data.area)
          }}{{
            data.area
              ? func.formatNumber(data.area, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + "㎡"
              : ""
          }}</span
        >
      </div>
    </div>
    <div class="col-12">
      <div class="my-2">
        <button
          v-if="textButton !== null"
          class="btn btn-green text-white text-nowrap"
          style="pointer-events: none">
          {{ textButton }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null },
    textButton: { type: String, default: null },
    buildingNumber: { type: String, default: null },
  },
  methods: {
    checkValues(a, b) {
      if (a !== null && b !== null) {
        return "/";
      }
    },
  },
};
</script>
