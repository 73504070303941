<template>
  <div class="single-page-footer py-4">
    <p>
      <img
        src="@/assets/img/property-feat/feat-foot-title.png"
        alt="title"
        class="img-fluid" />
    </p>
    <p>
      東京都渋谷区道玄坂1丁目9番5号 渋谷スクエアA 7F<br />
      国土交通大臣(12)第2611号
    </p>
    <p class="mb-0">
      一般社団法人不動産協会会員<br />
      一般社団法人不動産流通経営協会会員<br />
      公益社団法人首都圏不動産公正取引協議会加盟
    </p>
  </div>
</template>
