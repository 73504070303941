import { ORIGINAL_LOCATION } from "/src/constants.js";
const state = () => ({
  routeName: "",
});

const getters = {};

const actions = {
  setRouteName({ commit }, location) {
    if (location === ORIGINAL_LOCATION.DYNAMIC) {
      commit("setData", { name: "uuid-in-house" });
    } else {
      commit("setData", { name: "uuid-properties-other" });
    }
  },
};

const mutations = {
  setData(state, { name }) {
    state.routeName = name;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
