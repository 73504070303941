<template>
  <div
    :id="id"
    class="modal custom-modal fade"
    tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-4 text-center">
            <div class="fs-16 mb-4">
              {{ convertMessage(1) }} <br />
              {{ convertMessage(2) }}
            </div>
            <div class="text-center">
              <button
                class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
                data-dismiss="modal">
                {{ btnno }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalConfirmAlert",
  props: {
    id: { type: String, default: "comfirmAlert" },
    message: { type: String, default: "" },
    btnno: { type: String, default: "閉じる" },
  },
  methods: {
    convertMessage(num) {
      let msg = this.message.split("\n");
      if (num == 1) return msg[0];
      if (num == 2) return msg[1];
    },
  },
};
</script>
