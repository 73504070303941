function formatDate(dateString, buffer = false) {
  let date = new Date(dateString);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let currentDate = new Date();

  if (date > currentDate) {
    if (day <= 10) {
      return `${year}年${month}月上旬 予定`;
    } else if (day <= 20) {
      return `${year}年${month}月中旬 予定`;
    } else {
      return `${year}年${month}月下旬 予定`;
    }
  } else {
    if (buffer == true) {
      return "即入居可";
    }
    return `${year}年${month}月`;
  }
}

function checkValid(a, b) {
  if (a && b && a != 0 && b != 0) {
    return "、";
  }
}

function checkValidSf(a, b) {
  if (a && b && a != 0 && b != 0) {
    return "/";
  }
}

function getPreviousDay(dateTime) {
  if (dateTime === null) {
    return null;
  }
  let date = new Date(dateTime);
  date.setDate(date.getDate() - 1);
  let yyyy = date.getFullYear();
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let dd = String(date.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

function returnTextButton(blockClassification, completionDate) {
  const today = new Date();
  const oneYear = 365 * 24 * 60 * 60 * 1000;
  const completion = new Date(completionDate);
  const timeDifference = today - completion;
  if (blockClassification == "新築") {
    if (Math.abs(timeDifference) < oneYear || timeDifference <= 0) {
      return "新築";
    } else {
      return "建物完成後未入居";
    }
  } else if (blockClassification == "中古") {
    return "中古";
  } else if (blockClassification == "リノベーション") {
    return "リノベーション";
  } else {
    return blockClassification;
  }
}
function checkIsNull(a, b) {
  return (a != null && a != 0) || (b != null && b != 0);
}
function getUuid() {
  const url = window.location.href;
  const parts = url.split("/");
  const uuid = parts[parts.length - 1];
  return uuid;
}
function formatDateOfBuildingConfirmation(data) {
  if (data == "9999年01月01日" || data == null || data == "9999年1月1日") {
    return "-";
  } else {
    return data;
  }
}

export {
  formatDate,
  checkValid,
  getPreviousDay,
  returnTextButton,
  checkIsNull,
  getUuid,
  formatDateOfBuildingConfirmation,
  checkValidSf,
};
